import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Ilegales de regreso`}</em>{` > `}<em parentName="p">{`Se sacuden la tierra`}</em>{` > `}<em parentName="p">{`Los pedazos de cielo ajeno, de sol blanco.`}</em>{` > `}<em parentName="p">{`Los come el silencio, el hambre,`}</em>{` > `}<em parentName="p">{`Ya sólo son desierto por dentro.`}</em></p>
      <h3 parentName="blockquote"><em parentName="h3">{`Tizoc García`}</em></h3>
    </blockquote>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1648146932/teatro1_nav0jh.png",
        "alt": "Ximena jpg"
      }}></img></p>
    <p>{`Hablemos de Teatro del Norte. El neologismo surgió hace 25 años y hoy ese género dramático ya no es un archipiélago, como lo señalaba atinadamente Enrique Mijares, `}<strong parentName="p">{`son obras que no se ha quedado sólo en la zona norte del país, han traspasado fronteras físicas, ideológicas e intelectuales; es una zona híbrida en donde la identidad se basa en la fusión de culturas, entre México y Estados Unidos.`}</strong>{` Es una diversidad cultural en la que dramaturgos como Víctor Hugo Rascón Banda, Hugo Salcedo, Manuel Talavera, Enrique Mijares, Cutberto López, Pilo Galindo, Demetrio Ávila, Virginia Hernández y Ángel Norzagaray, por mencionar sólo algunos, han plasmado en sus obras una necesidad constante de reflejar su entorno de una manera coloquial, sin dejar a un lado la poética que los caracteriza como un territorio de contrastes.`}</p>
    <p>{`El contexto del norte está presente en la producción teatral de Ángel Norzagaray, para el director de `}<em parentName="p">{`Mexicali`}</em>{` a Secas existe una relación entre lo climático con el modo de vida de los habitantes, y se muestra en el estado anímico de sus personajes, sus problemas y necesidades locales. Elementos presentes en obras como `}<em parentName="p">{`El velorio de los mangos, Una isla llamada California, Según el cristal, La balada de Miguel Chivo y Cartas al pie de un árbol`}</em>{`, obra de la cual me gustaría reflexionar a propósito de su importancia y vigencia a más de 20 años de su estreno.`}</p>
    <p>{`En febrero de 2007 tuve el privilegio de conocer a Ángel Norzagaray en un viaje a Mexicali, recuerdo que llegué a Tijuana y mientras íbamos en la Rumorosa me contaba que esas historias que plasmaba en el teatro eran de personas cercanas, familia, amigos e incluso autobiográficas. Los personajes de la obra de Norzagaray son tan universales que pueden ocurrir en cualquier parte del mundo.`}</p>
    <p>{`En el camino hacia Tijuana de casi 2 horas le conté de mi experiencia cuando vi `}<em parentName="p">{`Cartas al pie de un árbol`}</em>{` en el Centro Nacional de las Artes; estaba en mi primer semestre de la licenciatura en aquel octubre de 2002 y recuerdo con detalle cada elemento de la puesta en escena. Una alfombra roja cruzaba el escenario y al fondo unas cruces que representaban los cuerpos de los inmigrantes no identificados en la frontera con Estados Unidos.`}</p>
    <p><strong parentName="p">{`No hacía falta nada más en el escenario, la imagen era tan poderosa que el simbolismo de las cruces y la alfombra eran suficientes para contarnos la historia de lazos que nunca se rompen, vínculos que se alimentan de fe,`}</strong>{` ese fue mi acercamiento a la `}<strong parentName="p">{`“Estética del desierto”`}</strong>{` de Norzagaray. Término que autodenominó al establecer la relación entre el paisaje y el hombre, pues la manifestación de una cultura y realidad local determinan a sus habitantes. Dichos elementos los planteaba en su labor como director y dramaturgo pues establecía un comportamiento en la totalidad de los elementos de la puesta en escena, procurando el mínimo de esfuerzo para lograr el máximo de expresión.`}</p>
    <p><em parentName="p">{`En Cartas al pie de un árbol`}</em>{` conocemos de la búsqueda de una madre -sorda- de su hijo -ciego-. Con rumbos contrarios, empecinados en su destino norte/sur, así como una búsqueda de raíces, de identidad en donde se entrecruzan caminos, dando como resultado un panorama lleno de contrastes, en donde el de dónde es `}<em parentName="p">{`el para dónde`}</em>{`. Hoy recuerdo con nostalgia esas `}<em parentName="p">{`Cartas al pie de un árbol`}</em>{` pues `}<strong parentName="p">{`no ha cambiado el panorama para los inmigrantes centroamericanos y mexicanos que en la búsqueda por una calidad de vida mejor se convierten en una cifra, números que borran la identidad de madres que buscan a sus hijos, familias que se desintegran con el paso de los kilómetros,`}</strong>{` todo hacia el norte, todo hacia arriba, siempre con la esperanza de llegar a esa tierra que traerá prosperidad pero a costa de la distancia y el olvido.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1648147144/teatro2_znch7k.png",
        "alt": "Ximena jpg"
      }}></img></p>
    <p><em parentName="p">{`Cartas al pie de un árbol`}</em>{` es una obra en que convergen lo poético con lo trágico pues para el protagonista en sus recuerdos radica su identidad, recuerdos de la infancia de los álamos, la sierra, el rancho, de ahí su necesidad de ir contactando al personaje de Lazarillo para que pueda escribir y con suerte su mamá lo encuentra:`}</p>
    <p>{`HIJO CIEGO: Pero ayúdame con algo.
LAZARILLO: Mjjj
HIJO CIEGO: A escribir
LAZARILLO: Yo no soy escritor
HIJO CIEGO: Yo te dicto. Es una idea que tengo,
LAZARILLO: ¿ y qué o pa’qué?
HIJO CIEGO: De lo que me voy acordando te dicto. Tú lo escribes y luego la pegamos, así, en los postes, al pie de los árboles. Cartas al pie del árbol, de muchos árboles con mis recuerdos; con lo que voy siendo conforme reconstruyo mis recuerdos. Así, si alguien lee y sus recuerdos embonan con los míos pues ya la hice ¿no? Me va a poder llevar de regreso a mi pueblo y me va a explicar bien quién soy`}</p>
    <p>{`Los personajes de `}<em parentName="p">{`Cartas`}</em>{`.. nos cuentan sus recuerdos en esa necesidad de encontrar el lugar a dónde regresar. La estructura fragmentada hace de esta obra que las imágenes sueltas las reconstruya el lector/espectador de tal manera que también “embonen” con los recuerdos propios. Si bien es una obra cuya temática es la migración me parece que su universalidad radica en la constante búsqueda de una identidad propia, de una esperanza en que las cosas siempre tienen que mejorar.`}</p>
    <p>{`Es imposible no escribir a propósito de esta obra de manera objetiva pues son de esos textos que conmueven hasta las lágrimas y que duelen hasta los huesos. No puedo dejar que al igual que al Hijo Ciego me vengan los recuerdos de mi historia personal, de mis experiencias que dolían en los 20, de mis esperanzas a los 30 y de mis inciertos 40. Hace ya más de un mes de la muerte de Norzagaray y se sienten tan presente en sus personajes, los recuerdo con nostalgia de Lazarillo, Zaurino, los novios, los polleros e indocumentados de `}<em parentName="p">{`Cartas al pie de un árbol`}</em>{`; la hospitalidad de Ángel en ese primer encuentro en Tijuana en la UABC, la disposición a contestar mis interrogantes por muy ingenuas que parecieran ahora a la distancia después de ese primer encuentro hace 14 años mientras recorriamos la frontera de Tijuana con Estados Unidos.`}</p>
    <p>{`Para Ángel Norzagaray, `}<em parentName="p">{`Cartas al pie de un árbol`}</em>{` era su obra favorita, me lo confesó en aquella entrevista en Mexicali, muy orgulloso de este texto entrañable y quizá una de las razones por las que la consideraba su testamento es por los recuerdos autobiográficos, la dualidad vida/muerte, verdad/mentira, encuentros/desencuentros que se entrelazan para contar una historia entrañable, mítica, llena de optimismo aunque no lo parezca, en donde las fronteras sentimentales son más fuertes que las físicas y como señala Ángel: “Al pie de nosotros, hay una historia que contar, nuestras cartas al pie de un árbol”.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      